import { reviews } from "./reviews";

export const meta = {
  name: "BharatCash - Instant Personal Loan App",
  description:
    "BharatCash is an easy and quick personal loan app, trusted by over thousands of people across India. You can get an easy and hassle-free loan of up to ₹ 2 lakhs with BharatCash -Instant Loan App.",
  image: "/logo.png",
  banner: "/banner.png",
  category: "#2 top free finance",
  lastUpdated: "14 Apr 2019",
  company: "BharatCash Financials",
  appDownloadLink: "",
  shortName: "BharatCash",
  email: "support@bharatcash.com",
  websiteUrl: "https://creditbee.cfd",
  phoneNumber: "+91 9864284324 ",
  screenshots: [
    "/showcase/1.png",
    "/showcase/2.png",
    "/showcase/3.png",
    "/showcase/4.png",
  ],
  about: (
    <>
      Get Instant Personal Loan on your finger tip
      <br />
      <br />
      BharatCash is an easy and quick personal loan app, trusted by over
      thousands of people across India. You can get an easy and hassle-free loan
      of up to ₹ 2 lakhs with BharatCash -Instant Loan App.
      <br />
      <br />
      BharatCash is a platform that facilitates Lending Partners authorized by
      RBI for the disbursal of loans.
    </>
  ),

  whatsNew: [
    {
      question:
        "✅ Fast application process: Apply for a personal loan in no time. Just fill in the necessary details and get instant cash.",
    },
    {
      question:
        "✅ Fully Digital: No long queues, no paperwork. Enjoy the easiest Personal Loan online.",
    },
    {
      question:
        "✅ Flexible Amount: Get a personal loan of up to Rs. 2,00,000 hassle-free.",
    },
  ],
  reviews: reviews,
  footer: [
    {
      title: "Terms of Service",
      url: "/terms",
    },
    {
      title: "Privacy",
      url: "/privacy",
    },
    {
      title: "About Us",
      url: "/about-us",
    },
    {
      title: "Why Choose Us",
      url: "/why-choose-us",
    },
  ],
};
