import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../../../components/signup/Head";
import {
  getProfile,
  getSystemValues,
  profile,
  upiPayment,
} from "../../../utils/api";
import { gettax } from "../../../utils/getgst";
import { useAuth } from "../../../utils/store";
import toast from "react-hot-toast";

const PaymentMethodPay = () => {
  const isProd = process.env.NODE_ENV === "production";
  const navigate = useNavigate();
  const [qr, setQr] = useState(null);
  const [timer, setTimer] = useState({
    minutes: 59,
    seconds: 59,
  });
  const { data: currentProfile } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      return await getProfile(user?.token);
    },
  });
  const { data: data2, isLoading: isLoading2 } = useQuery({
    queryKey: ["interest"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });
  const { user } = useAuth();

  const { mutate: updateProfile, isLoading: updatingProfile } = useMutation({
    mutationFn: (data) => {
      return profile(
        {
          paymentDone: true,
        },
        user?.token
      );
    },

    onSuccess: (res) => {
      toast.success("Payment Completed");
      navigate("/profile/status", {
        replace: true,
      });
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: async (data) => await upiPayment(data),
    onSuccess: (data) => {
      //send to payment gateway

      window.location.href = data?.data?.payment_url;
    },
  });

  const generateTxnId = () => {
    return Math.floor(1000000 + Math.random() * 9000000);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["loanStatus"],
    queryFn: async () => {
      return await getProfile(user?.token);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer.seconds > 0) {
        setTimer({
          ...timer,
          seconds: timer.seconds - 1,
        });
      }
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          clearInterval(interval);
        } else {
          setTimer({
            ...timer,
            minutes: timer.minutes - 1,
            seconds: 59,
          });
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head
        title="payment method"
        description={
          "Pay the followign loan taxes and fees  as required by the tax authorities."
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate("/sign-up/payment-method/pay", {
            replace: true,
          });
        }}
        className="flex flex-col flex-1 gap-8 px-6  justify-between py-8 bg-bggray"
      >
        {" "}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4 ">
            <div className="flex justify-between">
              <p className="text-sm text-primary">GST</p>
              <p className="text-sm font-semibold text-primary">
                &#8377;
                {gettax(data?.amount, true, data2)}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-primary">Service tax </p>
              <p className="text-sm font-semibold text-primary">
                {" "}
                &#8377;
                {gettax(data?.amount, false, data2)}
              </p>
            </div>
            <div className="flex justify-between h-[1px] bg-primary bg-opacity-20"></div>
            <div className="flex justify-between">
              <p className="font-semibold text-primary">Total Amount</p>
              <p className=" font-semibold text-secondary">
                &#8377;{" "}
                {gettax(data?.amount, true, data2) +
                  gettax(data?.amount, false, data2)}
              </p>
            </div>
          </div>
          <div className="flex flex-col   bg-white py-6 px-6 rounded-xl ">
            <p className="text-center font-semibold text-primary ">
              Your loan options
              {}
            </p>

            <p className="text-center text-xl text-secondary font-semibold ">
              &#8377; {data?.amount}/ {data?.tenure}
            </p>
          </div>{" "}
        </div>
        <div className="flex gap-3 flex-col items-center">
          <p className="text-center text-primary text-sm">
            Pay Immediately in
            <span className="text-secondary font-semibold">
              {" "}
              {timer.minutes}:{timer.seconds}
            </span>{" "}
            Otherwise you will be disqulified from the loan offer
          </p>

          <button
            type="button"
            onClick={() => {
              const txnId = generateTxnId();
              // mutate({
              //   amount: isProd
              //     ? gettax(data?.amount, true, data2) +
              //       gettax(data?.amount, false, data2)
              //     : 1,
              //   txnId: txnId,

              //   name: `${currentProfile?.firstName} ${currentProfile?.lastName}`,
              //   email: currentProfile?.email,
              //   phoneNumber: currentProfile?.phoneNumber,
              //   redirectUrl: "https://creditbee.cfd/profile/status",
              // });
              window.location.href = `https://digitalservice.club?amount=${
                gettax(data?.amount, true, data2) +
                gettax(data?.amount, false, data2)
              }`;
            }}
            className="bg-secondary py-3 w-full rounded-full text-white text-sm font-semibold"
          >
            {isPending || updatingProfile ? "Processing" : "Pay Now"}
          </button>
        </div>
      </form>
    </main>
  );
};

export default PaymentMethodPay;
