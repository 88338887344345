import React, { useEffect } from "react";
import Head from "../../components/signup/Head";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { checkStatusUpi, getSystemValues, profile } from "../../utils/api";
import toast from "react-hot-toast";
import { useAuth } from "../../utils/store";
import { useProfile, useSystemValues } from "../../utils/queries";

const Status = () => {
  const { user } = useAuth();
  const { data: currentProfile } = useProfile();
  console.log(currentProfile);
  const { data: systemValues, isLoading: systemValuesLoading } =
    useSystemValues();
  const {
    mutate: updateProfile,
    isLoading: updatingProfile,
    mutateAsync,
  } = useMutation({
    mutationFn: async (data) => {
      await profile(data, user?.token);
      return data;
    },

    onSuccess: (data) => {
      if (data?.loanRequest) {
        navigate("/profile/edit", {
          replace: true,
        });
      }
    },
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  useEffect(() => {
    if (
      currentProfile &&
      !currentProfile?.paymentDone &&
      !currentProfile?.paymentDoneTime &&
      !currentProfile?.paymentDoneEndTime
    ) {
      if (!systemValuesLoading) {
        const time = new Date();

        const utcTimePlusMins = time.setMinutes(
          time.getMinutes() + systemValues?.durationForLoan
        );
        updateProfile({
          paymentDone: true,
          paymentDoneTime: time.toUTCString(),
          paymentDoneEndTime: new Date(utcTimePlusMins).toUTCString(),
        });
      }
    } else {
      if (
        currentProfile?.paymentDone &&
        currentProfile?.paymentDoneTime &&
        currentProfile?.paymentDoneEndTime
      ) {
        const currentTime = new Date().getTime();
        const endTime = new Date(currentProfile?.paymentDoneEndTime).getTime();
        if (currentTime > endTime) {
          updateProfile({
            loanRequest: true,
          });
        }
      }
    }
  }, [systemValuesLoading, currentProfile]);
  console.log(systemValues);

  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head title="Loan Request" />
      <div className="flex flex-col flex-1 gap-8 px-4 md:px-6  justify-between py-8 bg-bggray">
        <div className="bg-white rounded-xl px-4 py-10 flex flex-col gap-6 items-center justify-center">
          <img
            src="/images/savings.svg"
            alt=""
            className="object-contain rounded-lg md:w-2/3 self-center "
          />
          <div className="flex flex-col items-center justify-center gap-6">
            <h1 className="text-center flex gap-2 items-center  text-2xl font-semibold">
              {updatingProfile || systemValuesLoading ? (
                <>
                  <Loading />
                  Fetching your loan status
                </>
              ) : (
                "Payment Success"
              )}
            </h1>
            <p className="text-center text-gray-500 text-sm ">
              It’s take 2-4 Working days to transfer the money to your bank
              account
              <br />
              <br />
              (Saturday & Sunday is non working days)
            </p>
          </div>
          <ul className="flex flex-col gap-6 mt-4 mb-4">
            {[
              "The fee paid by you is non-refundable, and it is also mentioned in our terms and conditions",
              "You have to wait till the loan amount is credited to you and if there are any requirements, we will let you know in the app",
              "For this you keep checking the app after some time.",
            ].map((item, index) => (
              <li key={index} className="flex gap-4 ">
                <div className="w-3 h-3 bg-secondaryDark rounded-full mt-1" />
                <p className="text-sm flex-1 text-gray-600">{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default Status;
