import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../../../components/signup/Head";
import {
  getProfile,
  getSystemValues,
  profile,
  upiPayment,
} from "../../../utils/api";
import { gettax } from "../../../utils/getgst";
import { useAuth } from "../../../utils/store";
import toast from "react-hot-toast";

const PaymentMethodPaySuccess = () => {
  const isProd = process.env.NODE_ENV === "production";
  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/profile/status", {
        replace: true,
      });
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-primaryDark text-white text-2xl font-bold flex flex-col items-center justify-center h-screen gap-4">
      <img src="/Success.gif" alt="" />
      <p className="-mt-40">Success</p>
    </div>
  );
};

export default PaymentMethodPaySuccess;
